import React, { useContext } from 'react'
import {
  Flex,
  Box,
  Container,
  Hero,
  Layout,
  Redirect,
  Dot,
  LanguageContext,
} from 'components'
import i18n from '../../config/i18n'
import locales from '../../config/locales'
import contactPortrait from '../../images/contact-portrait.jpg'
import heroImage from '../../images/contact-hero.jpg'
// Photo by Claudio Testa on Unsplash

const Dash = () => (
  <Box display={'inline'} color={'neutral.3'}>
    {' - '}
  </Box>
)

const Price = ({ children, ...props }) => (
  <Box color={'neutral.0'} fontSize={0} fontWeight={1} {...props}>
    {children} CHF
  </Box>
)

const Duration = ({ children, ...props }) => (
  <Box color={'neutral.3'} fontSize={0} fontWeight={0} {...props}>
    {children}
  </Box>
)

const VideoConference = ({ children, ...props }) => {
  const language = useContext(LanguageContext)
  const locale = locales[language]
  return (
    <Box color={'neutral.3'} fontSize={0} fontWeight={0} {...props}>
      ({locale.snippets.videoConference})
    </Box>
  )
}

const Session = ({ text, duration, price }) => (
  <Box marginY={10}>
    <Box
      fontSize={'18px'}
      fontWeight={1}
      display={'inline'}
      lineHeight={'32px'}
    >
      {text}
    </Box>
    <Box>
      {price && (
        <React.Fragment>
          <Price display={'inline'}>{price}</Price>
          <Dash />
        </React.Fragment>
      )}
      <Duration display={'inline'}>{duration}</Duration>
    </Box>
  </Box>
)

const Package = ({ title, price, sessions }) => (
  <Box marginTop={10}>
    <Box
      fontSize={'18px'}
      fontWeight={1}
      display={'inline'}
      lineHeight={'32px'}
    >
      {title}
    </Box>
    <Price marginBottom={2}>{price}</Price>
    {sessions.map(({ text, duration, video }, idx) => (
      <Box key={idx}>
        <Box
          fontSize={'14px'}
          fontWeight={1}
          display={'inline'}
          lineHeight={'22px'}
        >
          {text}
        </Box>
        <Dash />
        <Duration display={'inline'}>{duration}</Duration>
        {video && <VideoConference display={'inline'} marginLeft={1} />}
      </Box>
    ))}
  </Box>
)

export const ContactPage = ({ language = i18n.fallback }) => {
  const locale = locales[language]
  const page = locale.pages.contact
  return (
    <Layout language={language}>
      <Hero
        backgroundImage={heroImage}
        backgroundPosition={{ _: 'center 40%', sm: 'center 29%' }}
      />
      <Container marginBottom={8} marginTop={2}>
        <Box
          marginTop={{ _: 4, md: 10 }}
          marginX={{ _: 0, sm: 16 }}
          paddingX={{ _: 4, sm: 16 }}
          textAlign={'center'}
          fontSize={'22px'}
          lineHeight={'38px'}
        >
          {page.pageHeading}
        </Box>

        <Dot
          backgroundColor="neutral.1"
          marginY={{ _: 10, md: 16 }}
          align="center"
        />

        <Box marginX={8}>
          <Box as={'h1'} textAlign={'center'} fontFamily={'heading'}>
            {page.contactHeading}
          </Box>

          <Flex
            alignItems={'center'}
            marginTop={4}
            flexDirection={{ _: 'column', sm: 'row' }}
          >
            <Box width={{ _: 1, md: 1 / 2 }} textAlign={'center'} padding={8}>
              <Box fontWeight={1} fontSize={'20px'}>
                Joëlle Roy
              </Box>

              <Box marginTop={5}>
                <Box fontSize={0} fontWeight={1} marginBottom={1}>
                  {page.emailHeading}
                </Box>
                <Box
                  as={'a'}
                  color={'neutral.0'}
                  textDecoration={'none'}
                  href={`mailto:${locale.email}`}
                  styled={{
                    '&:hover': {
                      color: 'primary.pink',
                    },
                  }}
                >
                  {locale.email}
                </Box>
              </Box>

              <Box marginTop={5}>
                <Box fontSize={0} fontWeight={1} marginBottom={1}>
                  {page.phoneAndWhatsappHeading}
                </Box>
                <Box
                  as={'a'}
                  color={'neutral.0'}
                  textDecoration={'none'}
                  href={`tel:${locale.phone}`}
                  styled={{
                    '&:hover': {
                      color: 'primary.pink',
                    },
                  }}
                >
                  {locale.phone}
                </Box>
              </Box>

              <Box marginTop={5}>
                <Box fontSize={0} fontWeight={1} marginBottom={1}>
                  {page.addressHeading}
                </Box>
                {locale.address.map((line, idx) => (
                  <Box key={idx}>{line}</Box>
                ))}
              </Box>
            </Box>
            <Box width={{ _: 1, md: 1 / 2 }} padding={8}>
              <Box as={'img'} width={'100%'} src={contactPortrait} />
            </Box>
          </Flex>
          <Box justifyContent={'center'} textAlign={'center'}>
            <Box display={{ _: 'none', md: 'block' }}>
              <Box
                as={'iframe'}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2761.4647507237732!2d6.152043615861905!3d46.201208991982924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c6533d3fd9e31%3A0x7a6618ac3aa80551!2sAliotis%2C%20Location%20De%20Cabinets%20Pour%20Th%C3%A9rapeutes!5e0!3m2!1sen!2sdk!4v1586699627852!5m2!1sen!2sdk"
                width={{ _: '400px', sm: '800px', lg: '840px' }}
                height={{ _: '400px', md: '100px' }}
                frameBorder="0"
                style={{
                  border: '0',
                }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              />
            </Box>
          </Box>
        </Box>

        <Box marginX={8} marginTop={12}>
          <Box as={'h1'} textAlign={'center'} fontFamily={'heading'}>
            {page.pricesHeading}
          </Box>
          <Box
            fontSize={1}
            fontWeight={1}
            marginTop={4}
            paddingBottom={4}
            textAlign={'center'}
          >
            {page.pricesSubheading}
          </Box>

          <Box>
            {page.prices.map((item, idx) =>
              item.type === 'package' ? (
                <Package key={idx} {...item} />
              ) : (
                <Session key={idx} {...item} />
              )
            )}

            <Box>{page.houseCall}</Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default Redirect
